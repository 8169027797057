.btn{
  border-radius: 0px;
  border: none;
}
.btn-main{
  text-decoration: none;
  transition: all 0.6s ease-in-out;
  color: #fff;
  font-family: $ff-title;
  font-weight: 700;
  background-color: $cyan;
  &:hover{
    background-color: $cyan-dark;
  }
}