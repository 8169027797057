.white-overlay{
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // background-color:#fff;
  // z-index: 9999;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // transition: opacity 0.3s ease-in-out;
  .loader {
    height: 5vmin;
    pointer-events: none;
  }
  &.hide{
    opacity: 0;
    pointer-events: none;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .loader {
    animation: loader-spin infinite 2s linear;
  }
}

@keyframes loader-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
