.my-ul{
    list-style: none; /* Removes default bullets */
    padding-left: 15px;
    // list-style: none;
    // padding: 0;
    // margin: 0;
    // margin-left: 5px;
    li{
        position: relative; /* Enables absolute positioning for pseudo-elements */

        padding: 0.3rem 0;
        font-size: 0.9rem;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 4px;
            background-color: $green;
            /* border-radius: 50%; */
            transform: translate(-14px, 13px);
        }
    }
}