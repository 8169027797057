.accordion{
  &-body{
    padding: 0;
    @include respond-to(xlarge){
      padding: 0 15px;
    }
  }
  .accordion-item{
    border: none;
    margin: 10px auto;
    background: none;
    &-chosen{
    margin: 40px auto;
    }
    .accordion-collapse{
      background: rgba(255,255,255,0.5);
      &:after{
      filter: blur(5px); // Add blur effect here
      }
    }
    .accordion-header{
      .accordion-button{
        padding: 30px;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background: none;
        overflow: hidden;
        @include respond-to(large){
          overflow: visible;
        }
        &:hover{
          .accordion-button-title{
          &-background-overlay{filter: invert(10%)!important;}
          &-text{color: $grey!important;}
          &-description{color: $grey!important;}
          }
        }
        &::after{
          background: none;
        }
        &-image{
          
          transition: all 0.6s ease-in-out;
          width: 124px;
          text-align: center;
          padding: 6px 6px;
          margin-left: 15px;
          height: 124px;
          background: #fff;
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px;
        }        
        &.collapsed{
          background: none;
          .accordion-button-title{
            font-weight: 400;
            color: $grey-darker;
            margin-left: 15px;
            &-background-overlay{
              filter: invert(90%);
              left: 0;
            }
            &-text{
              left: 85px;
              opacity: 1;
              color: $grey-darker;
            }
            &-description{
              transition: all 0.6s ease-in-out;
              font-size: 1.2rem;
              margin-left: 0px;
              color: $grey-darker;
            }
            &-text-large{
              top: 38px;
              left: 42px;
              opacity:0;
            }
          }
        }
        &-title{
          margin-bottom:0;
          margin-left: -5px;
          font-size: 1.2rem!important;
          transition: all 0.6s ease-in-out;
          // color: $grey-light;
          &-text{
            transition: all 0.6s ease-in-out;
            font-weight: 700;
            font-size: 1.2rem;
            color: $grey;
            opacity: 0;
            z-index: 3;
            margin-top: 5px;
            margin-bottom: 5px;

          }
          &-description{
            transition: all 0.6s ease-in-out;
            font-size: 1.2rem;
            color: $grey;
            margin-left: 50px;
          }
          &-text-large{
            transition: all 1.3s ease-in-out;
            position: absolute;
            top: 38px;
            left: 205px;
            @include respond-to(xlarge){
            left: 205px;
            }
            font-weight: 700;
            font-size: 2.8rem;
            color: $white;
            z-index: 3;

          }
          &-background-overlay{
            filter: invert(20%);
            transition: all 1.6s ease-in-out;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center right;
            // background: $grey-darker;
            position: absolute;
            top: 0;
            left: -50vw;
            right: 0px;
            bottom: 0;
            z-index: -1;
          }

        }
      }
    }
  }
}
.body-accordion{
  .accordion-header{
    background-color: $grey-light;
    .accordion-button{
      
      background-color: #ddd!important;
      color: #333;
      font-weight: 600;

      &:hover, &:focus{
        background-color: #ddd!important;
      }
      &.collapsed{
        background-color: #eee!important;
      }

    }
  }
  .accordion-body{
    background-color: #f4f4f4;
    padding: 15px;
  }
}