$base_body: 1rem;
$base_body_lg: 1.125rem;


body{
    font-family: $ff-main;
    font-size: $base_body;
    @include respond-to(xlarge){
        font-size: $base_body_lg;
    }
    a{
        color: #000000;
        transition: all 0.3s ease-in-out;
        text-decoration: underline;
        text-decoration-color: #eceef3;
        text-decoration-thickness: 4px;
        &:hover, &:focus{
            text-decoration-color: #333;
        }
        
    }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
    font-family: $ff-title;
    font-weight: 700;
    color: $grey-darker;
}

$base_h1: 4.5rem;

h1, .h1{
    font-size: calc(#{$base_h1} * .4 + 1.5vw);
    @include respond-to(xlarge){
        font-size: $base_h1;
    }
}

h2, .h2{
    @include respond-to(xlarge){
        font-size: calc(#{$base_h1} * 2 / 2.875);
    }
}

h3, .h3{
    @include respond-to(xlarge){
        font-size: calc(#{$base_h1} * 1.75 / 2.875);
    }
}

h4, .h4{
    @include respond-to(xlarge){
        font-size: calc(#{$base_h1} * 1.5 / 2.875);
    }
}

h5, .h5{
    @include respond-to(xlarge){
        font-size: calc(#{$base_h1} * 1 / 2.875);
    }
}

h6, .h6{
    font-size: 0.9rem;
    @include respond-to(xlarge){
        font-size: calc(#{$base_h1} * .6 / 2.875);
    }
}

.subtitle{
    font-family: $ff-title;
    font-size: 1.1rem;
    font-weight: 800;
}

.large{
    font-size: 1.125rem;
}
small, .small{
    font-size: .875rem;
}

.script{
    font-family: $ff-main;
}

.bold{
    font-weight: bold;
}
.coming-soon{
    font-size: 1rem;
}