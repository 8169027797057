.fade-in {
  animation: fadeIn ease 1.5s;
  animation-fill-mode: forwards;
}

.fade-out {
  animation: fadeOut ease 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  20% { opacity: 0;}
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  50% { opacity: 0;}
  to { opacity: 0; }
}

