

// Color map
$black      : #222222;
$blue-dark  : #0037b1;
$blue-darker: #032D60;
$blue-medium: #215CA0;
$blue       : #215CA0;
$blue-light : #00A1E0;
$cyan-dark  : #049c8d;
$cyan       : #04e1cb;
$cyan-light : #E3F7FF;
$white      : #FFF;
$green      : #00B2A9;
$downflow   : #139400;
$yellow     : #EBBC48;
$red        : #FD7456;
$purple     : #7D52C2;
$brown      : #784834;
$grey       : #f4f4f4;
$grey-med   : #c4c4c4;
$grey-light : #F1F1F1;
$grey-dark  : #7E7E7E;
$grey-pass  : #888888;
$grey-darker: #555;

$c-white: #FFF;
$c-black: #000;
$c-green-vogue: #032D60;
$c-tory-blue: #0B5CAB;
$c-lochmara: #0176D3;
$c-malibu: #7BD1F8;
$c-lightning-yellow: #FDC516;
$c-carnation-pink: #FF92BE;
$c-solitude: #EAF5FE;
$c-scarpa-flow: #59575C;
$c-corn: #E4A201;
$c-niagara: #06A59A;
$c-brown-derby: #4A2413;
$c-banana-mania: #FBE8A3;
$c-dove-gray: #666;
$c-gallery: #EEE;
$colors: (
    'black'       : $black,
    'white'       : $white,
    'grey'        : $grey,
    'grey-med'    : $grey-med,
    'grey-light'  : $grey-light,
    'grey-pass'   : $grey-pass,
    'grey-dark'   : $grey-dark,
    'grey-darker' : $grey-darker,
    'blue'        : $blue,
    'blue-light'  : $blue-light,
    'blue-medium' : $blue-medium,
    'blue-dark'   : $blue-dark,
    'green'       : $green,
    'yellow'      : $yellow,
    'cyan'        : $cyan,
    'downflow'    : $downflow,
);

// Loop to spit out color for fonts and colors for backgrounds & text
@each $name,
$color in $colors {
    .t-#{$name} {
        color: $color!important;
    }

    .bg-#{$name} {
        background-color: $color!important;
    }
}

// THEME COLORS
