@import "./styles/mixin.scss";
@import "./styles/variables.scss";
@import "./styles/colors.scss";
@import "./styles/typography.scss";
@import "./styles/theme.scss";
@import "./styles/elements/embed.scss";
@import "./styles/elements/accordion.scss";
@import "./styles/elements/button.scss";
@import "./styles/elements/nav.scss";
@import "./styles/elements/ul.scss";
@import "./styles/elements/animation.scss";
@import "./styles/elements/doc.scss";
@import "./styles/elements/container.scss";
@import "./styles/elements/modal.scss";
@import "./styles/elements/form.scss";
@import "./styles/sections/hero.scss";
// #bg{
//   &::before{
//     z-index: -1;
//     position: fixed;
//     content: '';
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     // background-image: url('../public/images/bb_bg_top3.jpg');
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: top center;
//   }
//   &::after{
//     z-index: -1;
//     position: fixed;
//     content: '';
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     // background-image: url('../images/bb_bg_bot.jpg');
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: bottom center;
//   }
// }
#bg {
  // background-image: url('../public/images/bb_bg_bot.jpg');
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center, bottom center;
  transition: background-image 0.5s ease-in-out; // Add transition for crossfade effect

}