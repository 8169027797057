.main-title{

}
.main-subtitle{
  font-family: $ff-title;  
  margin-top: -10px;
  font-size: 0.7rem;
  @include respond-to(xlarge){
    margin-top: -20px;
  font-size: 0.9rem;
  }
}