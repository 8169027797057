

// Fonts
$ff-title: "Montserrat", sans-serif;
$ff-main: "Nunito", serif;

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-demi: 600;
$fw-semibold: 600;
$fw-bold: 700;