.doc{
  &-update-date{
    text-align: right;
    font-size: 0.6rem;
    margin-right: 40px;
    @include respond-to(xlarge){
      font-size: 0.7rem;
    }
    color: $grey-med;
    margin-bottom: 15px;
    z-index: 4;
  }
  &-image{
    max-width: 100%;
    min-width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: ease-in-out 0.3s;
    &:hover, &:focus{
      transform: scale(1.02) translateY(-5px);
      box-shadow: 0px 5px 10px rgba(0, 1, 0, 0.1);
    }
  }
  &-sidenote{
    // font-size: 0.95rem;
    background-color: $grey-light;
    padding: 20px;
    margin: 40px 0;
    a{
      text-decoration-color: $grey-med;
    }
    p::last{
      margin-bottom: 0;
    }
  }
  &-video{
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    max-width: 100%;
  }
}