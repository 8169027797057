.mailer{
  position: relative;
  &-link{
    position: absolute;
    top: 0px;
    right: 0px;
    &-image{
      width: 30px;
    }
  }
}
.logo {
  transition: all 0.3s ease-in-out;
  width: 220px;
  margin-top: 30px;
  margin-bottom: 10px;
  @include respond-to(large) {
  // padding: 30px;

  }
  &.large {
  }

  &.small {
    width: 150px;
  }
}