.modal{
  &-header{
    border-bottom: none;
  }
  &-content{
    border-radius: 0;
  }
  .btn-close{
    margin-right: 0.4rem!important;
    font-weight: 700;
    color: #555!important;
  }
}